.main {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;
}

.subTitle {
  font-size: 20px;
  font-family: "Editorial";
  font-weight: 500;
  line-height: 150%;
  margin-bottom: 0rem;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border: 1px solid var(--primary-border);
  color: var(--text-main);
  font-family: "Editorial";
  font-weight: 400;
  margin-bottom: 0rem;
  white-space: nowrap;
  border-radius: 3px;
  padding: 10px 20px;
  width: fit-content;
  transition: 0.1s ease-in;
}

.strong {
  font-weight: 500;
  width: 91.03px;
  display: inline-block;
}

.button:hover {
  cursor: pointer;
  border: 1px solid var(--text-main);
}

.p {
  font-size: 16px;
  font-family: "Editorial";
  text-align: center;
  font-weight: 400;
  line-height: 180%;
  margin-bottom: 0rem;
  margin-top: 0rem;
}
@media (max-width: 600px) {
  .button {
    font-size: 14px;
  }

  .subTitle {
    font-size: 18px;
  }

  .p {
    font-size: 14px;
  }

  .strong {
    width: 80px;
  }
}
