.modalTitle {
	text-align: center;
	width: 100%;
	font-size: 16px;
}

.topSection {
	padding: 1rem;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.title2 {
	text-align: center;
	font-size: 25px;
	margin: 1em;
	height: auto;
}

.logo {
	position: absolute;
	left: 0;
	margin-left: 1em;
	margin-top: 2px;
}

.separator {
	margin-top: 1em;
	display: flex;
	align-items: center;
	margin-bottom: 1em;
}

.space2 {
	margin-top: 1rem;
}

.separatorText {
	color: #99a3a6;
	padding: 0 0.5rem;
	margin-bottom: 0rem !important;
}

.line {
	flex: 1;
	height: 1px;
	background-color: #99a3a6;
}

.offerText {
	text-align: center;
	font-size: 16px;
	margin-bottom: 18px;
}

.closeIcon {
	z-index: 20;
	position: absolute;
	top: 10px;
	right: 10px;
	width: 30px;
	height: 30px;
}

.closeIcon:hover {
	cursor: pointer;
}

.offerSection {
	justify-content: space-evenly;
	display: flex;
	flex-direction: row;
}

.amount {
	font-size: 16px;
	margin-left: auto;
	/* font-weight: 600; */
	color: black;
}

.amountActive {
	color: white;
	font-weight: 600;
	font-size: 16px;
	margin-left: auto;
	/* font-weight: 600; */
}

.percentAmount {
	color: #99a3a6;
	font-size: 0.75rem;
}

.percentAmountActive {
	color: white;
	font-size: 0.75rem;
}

.activeBox {
	width: 30%;
	text-align: center;
	padding: 0.75rem;
	background-color: var(--primary-purple) !important;
	color: white !important;
	border-radius: 5px;
	transition: 0.2s ease;
}
.offerBox {
	width: 30%;
	text-align: center;
	padding: 0.75rem;
	border: 1px solid #cdced2;
	border-radius: 5px;
	transition: 0.2s ease;
}

.offerBox:hover {
	cursor: pointer;
}

.modalText {
	text-align: center;
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 32px;
}

.submitButton {
	background-color: var(--text-main);
	border-radius: 3px;
	border: 0 !important;
	width: 100%;
	font-family: 'Editorial';
	align-self: center;
}

.deleteButton {
	background-color: #f95151;
	border-radius: 3px;
	border: 0 !important;
	width: 100%;
	align-self: center;
}

.yourOffer {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	font-size: 20px;
	font-weight: 500;
}

.textGreen {
	margin-left: 0.2ch;
	color: var(--primary-purple);
}

.listingCard {
	padding: 32px;
	border-radius: 10px;
	position: relative;
	width: 100%;
	max-width: 40em;
	margin-left: auto;
	margin-right: auto;
}

.imgSizeModal {
	margin-left: auto;
	margin-right: auto;
	max-height: 8em;
	min-height: 8em;
	width: inherit;
	object-fit: contain;
}

.offerSent {
	text-align: center;
	padding-top: 1rem;
	font-weight: 600;
	font-size: 14px;
	color: #99a3a6;
}
.details {
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	border-radius: 5px;
	padding: 2px;
	display: block;
	height: auto;
	margin-top: 0.2px;
	text-align: left;
	margin-right: 1em;
}

.offerModalBody {
	text-align: center;
	font-weight: 600;
	color: #0e5c31;
}

.inputDiv {
	width: 100%;
	display: flex;
	justify-content: center;
}

.offerInput {
	border: 1px solid #cdced2;
	border-radius: 3px;
	height: 44px;
	/* width: 100px; */
	padding-left: 1.5rem;
	font-size: 20px;
	display: inline-block;
}

.inputWrapper {
	position: relative;
	width: fit-content;
	height: fit-content;
}

.dollarSign {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	left: 10px;
	color: var(--primary-purple);
	font-weight: 600;
	font-size: 20px;
}

/* Buying & Selling Modal */

.main {
	position: relative;
	padding-left: 20px;
	padding-right: 20px;
}

.main1 {
	position: relative;
}

.infoBox {
	display: flex;
	margin-bottom: 10px;
}

.iconModalBuy {
	width: 7em;
	height: 7em;
	padding: 1em;
	border-radius: 20px;
	color: white;
	background-color: var(--primary-purple);
	margin-right: 20px;
}

.iconModal {
	width: 7em;
	height: 7em;
	padding: 1em;
	border-radius: 20px;
	color: white;
	background-color: var(--primary-purple);
	margin-right: 20px;
}

@media (max-width: 600px) {
	.infoBox {
		flex-wrap: wrap;
		text-align: center;
	}
	.infoSection {
		margin: auto;
	}

	.iconModalBuy {
		width: 5em;
		height: 5em;
		margin-right: 0;
		margin-bottom: 5px;
	}

	.iconModal {
		width: 5em;
		height: 5em;
		margin-right: 0;
		margin-bottom: 5px;
	}
}

.header {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	font-size: 16px;
	font-weight: 800;
}

.infoText {
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 1rem;
}

.map {
	height: 8em;
}

.noMargin {
	margin: 0 !important;
}

/* PBar */
.pBox {
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	margin-bottom: 2rem;
	margin-top: 1.5rem;
	padding-left: 1rem;
	padding-right: 1rem;
}
.box {
	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: center;
}

.number {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--primary-bg);
	width: 2em;
	height: 2em;
	margin-right: auto;
	margin-left: auto;
	border-radius: 1000px;
	transition: 1s;
}

.deliveryIcon {
	background-color: var(--primary-bg);
	color: var(--primary-purple);
	min-height: 3em;
	min-width: 3em;
	border-radius: 8px;
	padding: 8px;
}

.flexRow1 {
	display: flex;
	flex-direction: row;
	margin-top: 0.5rem;
}

.flexRow2 {
	display: flex;
	flex-direction: row;
}

.address {
	padding-left: 1rem;
	padding-right: 1rem;
	color: #99a3a6;
	font-size: 14px;
	margin-bottom: auto;
	margin-top: auto;
}

.numberActive {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--primary-purple);
	color: white;
	width: 2em;
	height: 2em;
	margin-right: auto;
	margin-left: auto;
	border-radius: 1000px;
	transition: 1s;
}

.boxText {
	font-size: 12px;
	margin-top: 0.2rem;
	width: 90px;
}

.line {
	display: flex;
	align-items: center;
	max-width: 100%;
	width: 95%;
	margin-left: auto;
	margin-right: auto;
	transition: 0.3s;
	position: relative;
}

.lineOne {
	border-radius: 50px;
	flex: 1;
	height: 3px;
	background-color: var(--primary-bg);
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	transition: 1s;
}

.lineOneActive {
	border-radius: 50px;
	flex: 1;
	height: 3px;
	background-color: var(--primary-purple);
	transition: 1s;
}

/* Done */

.textSize {
	font-size: 14px;
}

.offCanvas {
	overflow: scroll;
}

.inputSection {
	background-color: white;
	width: 48%;
	padding: 1em;
	display: flex;
	flex-direction: column;
	border-radius: 8px;
	border: 1px solid var(--primary-bg);
	background-color: var(--primary-bg) 3a;
}
.inputSection1 {
	margin-top: 0.6em;

	background-color: white;
	width: fit-content;
	min-width: 100%;

	padding: 1rem;
	display: flex;
	flex-direction: column;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.flexRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.icon {
	position: absolute;
	top: 10px;
	left: 0;

	margin-left: 10px;
	margin-right: 10px;

	height: 25px;
	width: 25px;
}

.row {
	display: flex;
	flex-direction: row;
}

.pointer {
	cursor: pointer;
	position: relative;
	text-align: center;
	color: white;
	border-radius: 10px;
	width: inherit;
	margin-block: 5px;
	padding: 5px;
	background-color: #f7941d;
	border: none;
}

.absolute {
	color: var(--primary-purple);
	position: absolute;
	right: 0px;
	z-index: 2;
}

.pointer:hover {
	transform: scale(1.01);
	background-color: #ffbf00;
	font-weight: 600;
	min-width: 100% !important;
}

.errors {
	color: var(--primary-red-400);
	width: 100%;
	text-align: center;
	margin-bottom: 0rem !important;
}

.leftSection {
	min-width: 10em;
	width: 100%;
	padding: 0.5em;
}

.rightSection {
	padding: 0.5em;
	padding-left: 1em;
	font-size: 14px;
}

.addressText {
	margin-top: 2em;
}

@media (max-width: 1000px) {
	.flexRow {
		flex-wrap: wrap-reverse;
	}

	.inputSection1 {
		width: 100%;
		margin-left: 0;
	}
}

/* Layout */

.container {
	display: flex;
	width: 70%;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	border-radius: 20px;
	margin: auto;
	margin-top: 20px;
	padding: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: white;
}

.col {
	display: flex;
	justify-content: center;
}

/* text */

.instructions {
	font-size: 2.4vh;
	font-weight: 500;
	text-align: center;
	padding: 10px;
	height: auto;
}

.listingItem {
	font-size: 2.4vh;
	width: 100%;
	border-bottom: solid rgba(235, 235, 235, 0.822) 1.5px;
	padding: 17px;
}

.title {
	font-size: 3vh;
}

.quantityInput {
	max-width: 100px;
}

/* listingImg */

.imgSize {
	width: 100%;
}

.carousel {
	width: 300px;
	display: inline-block;
}

/* Map */

/* imgLarge */

.carouselLarge {
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	overflow: hidden;

	position: relative;
}

@media (min-width: 600px) {
	.listingImg:hover {
		transform: scale(1.5);
		transition: 0.2s ease;
	}
}

.listingImg {
	height: inherit;
	width: inherit;
	object-fit: cover;
	transition: 0.2s ease;
}

.map {
	width: 100%;
	min-width: 300px;
	margin-bottom: 1em;
}

/* Payment Stuff */

.paymentContainer {
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	padding: 40px;
	border-radius: 10px;
}
.navButtons {
	display: flex;
	margin-top: 1rem;
	justify-content: center;
	margin-bottom: 10rem;
}

.navButton {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px !important;
	width: 95%;
}

.navButtonBack {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	border-radius: 5px !important;
	width: 95%;
	border: 0 !important;
	box-shadow: none !important;
	background-color: transparent !important;
}

.stripeEl {
	border-radius: 6px;
	margin-bottom: 6px;
	padding: 12px;
	border: 1px solid rgba(50, 50, 93, 0.1);
	height: 44px;
	font-size: 16px;
	width: 100%;
	background: white;
	margin-top: 1em;
}

.cardGroupSize {
	width: 100%;
	-webkit-margin-start: auto;
	-webkit-margin-end: auto;
	margin-inline: auto;
	display: flex;
	flex-direction: column;
	background-color: white;
}

.infoIcon {
	position: absolute;
	color: #99a3a6;
	right: 1;
	margin-left: 1px;
	cursor: pointer;
}

.colorText {
	color: var(--primary-purple);
	font-weight: 600;
}

.header {
	padding-top: 1em;
	padding-bottom: 1em;
}
.payTitle {
	font-weight: 600;
}

.onHover {
	cursor: pointer;
}

.onHover:hover {
	color: var(--primary-purple);
}

.stripeLogo {
	width: 10em;
}

.payPrice {
	color: var(--primary-purple);
	font-weight: 600;
	text-align: end;
}

.qty {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin: auto;
}
.totalQty {
	color: #99a3a6;
	margin-left: 1rem;
	margin-right: 1rem;
}

.payInfo {
	font-size: 16px;
	font-style: italic;
	color: gray;
	margin-top: 1em;
	margin-bottom: 1em;
	border-bottom: solid rgba(235, 235, 235, 0.822) 1px;
	max-width: 80%;
}

.marginInline {
	-webkit-margin-start: 1em;
	-webkit-margin-end: 1em;
	margin-inline: 2em;
}

.paymentButton {
	margin-top: 1em;
	width: 100%;
}

@media (max-width: 700px) {
	.container {
		width: 100%;
	}
}

/* Layout */

.container {
	display: flex;
	width: 70%;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	border-radius: 20px;
	margin: auto;
	margin-top: 20px;
	padding: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: white;
}

.col {
	display: flex;
	justify-content: center;
}

/* text */

.instructions {
	font-size: 2.4vh;
	font-weight: 500;
	text-align: center;
	padding: 10px;
	height: auto;
}

.listingItem {
	font-size: 2.4vh;
	width: 100%;
	border-bottom: solid rgba(235, 235, 235, 0.822) 1.5px;
	padding: 17px;
}

.title {
	font-size: 3vh;
}

.quantityInput {
	max-width: 100px;
}

/* listingImg */

.imgSize {
	width: 100%;
}

.carousel {
	width: 300px;
	display: inline-block;
}

/* Map */

.map {
	width: 100%;
	min-width: 300px;
}

/* Payment Stuff */

.paymentContainer {
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	padding: 40px;
	border-radius: 10px;
}

.navButtons1 {
	display: flex;
	margin-top: 1rem;
	justify-content: space-between;
}

.cardGroupSize {
	width: 100%;
	-webkit-margin-start: auto;
	-webkit-margin-end: auto;
	margin-inline: auto;
	display: flex;
	flex-direction: column;
	background-color: white;
}

.infoIcon {
	position: absolute;
	color: #99a3a6;
	right: 1;
	margin-left: 1px;
	cursor: pointer;
}

.colorText {
	color: var(--primary-purple);
	font-weight: 600;
}

.header {
	padding-top: 1em;
	padding-bottom: 1em;
}
.payTitle {
	font-weight: 600;
}

.onHover {
	cursor: pointer;
}

.onHover:hover {
	color: var(--primary-purple);
}

.stripeLogo {
	width: 10em;
}

.payPrice {
	color: var(--primary-purple);
	font-weight: 600;
	text-align: end;
}

.payInfo {
	font-size: 16px;
	font-style: italic;
	color: gray;
	margin-bottom: 1em;
	border-bottom: solid rgba(235, 235, 235, 0.822) 1px;
	max-width: 80%;
}

.marginInline {
	-webkit-margin-start: 1em;
	-webkit-margin-end: 1em;
	margin-inline: 2em;
}

.error {
	color: var(--primary-red-400);
	text-align: center;
	padding: 1em;
}

.paymentButton {
	background-color: var(--primary-purple) !important;
	border: 0 !important;
	font-family: 'Editorial';

	margin-top: 30px;
	width: 100%;
}

.paymentButtonDisabled {
	background-color: var(--primary-purple);
	opacity: 0.4;
	font-family: 'Editorial';
	border: 0 !important;
	margin-top: 30px;
	width: 100%;
}

.white {
	margin-left: 0.2rem;
	height: 1rem;
	width: 1rem;
	margin-bottom: 0.1rem;
	color: white !important;
}

.payment {
	background-color: #99a3a6 !important;
}

.protectedBox {
	width: 100%;
	display: flex;
	flex-direction: row;
}

.protectTextBox {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.learnMore {
	text-decoration: underline;
	display: inline;
}
.learnMore:hover {
	cursor: pointer;
}

.protectHeader {
	font-size: 16px;
	font-weight: 600;
}
.protectBody {
	font-size: 14px;
	color: #6b6b6b;
	line-height: 20px;
	letter-spacing: -0.16px;
}

.shieldIcon {
	margin-top: auto;
	margin-bottom: auto;
	color: #68b368;
	height: 50px;
	width: 50px;
	margin-right: 0.75rem;
}

.cardLogo {
	max-height: 2em;
	max-width: 2em;
	display: inline;
	margin-left: 1em;
}

.orderSummary {
	margin: 0rem;
}

.hoverBoxP {
	font-size: 12px;
	margin-top: 1rem;
	margin-bottom: 0rem;
	color: var(--text-mute);
}

.breakdownSection {
	border: 1px solid var(--primary-border);
	padding: 1em;
	border-radius: 5px;
}

.cardDiv {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	width: 100%;
}

.cardPreview {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin: auto;
	width: 100%;
	border: 0.5px solid rgba(189, 189, 189, 0.627);
	border-radius: 7px;
	margin-bottom: 0.5em;
	padding: 0.7em 0.4rem;
	color: gray;
	position: relative;
}

.dollarSignCrossed {
	color: #b86363;
	font-size: 12px;
	font-weight: 800;
}

.listingPriceCrossed {
	background-color: rgba(183, 99, 99, 0.15);
	font-size: 16px;
	color: #450a0a;
	font-weight: 600;
	border-radius: 8px;
	padding: 5px;
	padding-left: 20px;
	padding-right: 20px;
	width: fit-content;
	display: flex;
	flex-direction: row;
	position: relative;
	text-decoration: line-through;
}

.cardPreviewActive {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin: auto;
	width: 100%;
	border: 0.5px solid var(--primary-purple);
	border-radius: 7px;
	margin-bottom: 0.5em;
	padding: 0.7em 0.4rem;
	color: gray;
	position: relative;
}

.circle {
	margin-left: 0.75rem;
	margin-right: 0.75rem;
	width: 20px;
	height: 20px;

	margin-top: auto;
	margin-bottom: auto;
	border-radius: 100%;
	border: 0.5px solid #99a3a6;
}

.circleActive {
	margin-left: 0.75rem;
	margin-right: 0.75rem;

	width: 20px;
	height: 20px;
	margin-top: auto;
	margin-bottom: auto;
	border-radius: 100%;
	border: 0.5px solid var(--primary-purple);
	background-color: var(--primary-purple);
}

/* .cardPreview:hover {
	  cursor: pointer;
	  color: white;
	  background-color: rgba(168, 168, 168, 0.398);
	  border: 0.5px solid white;
  } */

/* .cardPreviewAdd:hover {
	  cursor: pointer;
  } */

.cardPreviewAdd {
	display: flex;
	align-items: center;
	padding: 1em;
	color: var(--primary-purple);
}

.cardNumber {
	text-align: left;
	width: 50%;
	margin-left: 2em;
	/* margin-right: 3em; */
	color: #6b6b6b;
}

.addIcon {
	color: var(--primary-purple);
	height: 24px;
	width: 24px;
	display: inline;
	margin-right: 0.75rem;
}

.addText {
	font-weight: 500;
	margin-top: auto;
	margin-bottom: auto;
	font-size: 16px;
	height: 24px;
}

.removeIcon {
	color: #99a3a6;
	height: 25px;
	width: 25px;
	position: absolute;
	margin-top: auto;
	margin-bottom: auto;
	right: 1em;
}

.removeIcon:hover {
	cursor: no-drop;
}

.paymentMethodSection {
	margin-top: 1rem;
	margin-bottom: 0rem;
}

.orPayWith {
	width: 100%;
	text-align: center;
	font-weight: 600;
	padding: 1em;
}

.paypalPayment {
	width: 80%;

	margin: auto;
}

@media (max-width: 700px) {
	.container {
		width: 100%;
	}
}

.buttonDelivery {
	width: 45%;
	height: 3em;
	margin-left: auto;
	margin-right: auto;
	color: var(--primary-purple) !important;
	background-color: white !important;
}

.buttonPickup {
	height: 3em;
	margin-left: auto;
	margin-right: auto;
	width: 45%;
}

.Link {
	text-decoration: none;
	color: black;
}

.promo {
	height: 44px !important;
	font-size: 14px !important;
	border-radius: 6px !important;
	border: 1px solid rgba(50, 50, 93, 0.1) !important;
}

.icon {
	color: white;
	width: 20px;
	height: 20px;
}

.submitButton:hover {
	cursor: pointer;
	transform: scale(1.01);
	opacity: 0.8;
}

.deletedOffer {
	width: 100%;
	text-align: center;
	color: gray;
	font-size: large;
	margin-top: 1em;
}

.deleteButton:hover {
	cursor: pointer;
	transform: scale(1.01);
}

.submitCounterOffer {
	width: 100%;
	background-color: var(--primary-purple);
	color: white;
	border-radius: 7px;
	margin-top: 1em;
	padding: 0.3em;
}

.promoBox {
	margin-bottom: 1.5rem;
	display: flex;
	flex-direction: row;
}

.flex {
	display: flex;
	flex-direction: row;
}

.infoText {
	font-size: 18px;
	font-weight: 800;
	margin-bottom: 1rem;
}

.deliveryIcon {
	background-color: var(--primary-bg);
	color: var(--primary-purple);
	min-height: 3em;
	min-width: 3em;
	border-radius: 8px;
	padding: 8px;
}

.flexRow {
	display: flex;
	flex-direction: row;
}
.flexRow1 {
	display: flex;
	flex-direction: row;
	margin-top: 0.5rem;
}

.sub {
	color: #6b6b6b;
	font-size: 14px;
}

.paySec {
	display: flex;
	flex-direction: row;
	margin-bottom: 0.2rem;
}
.main {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.finalAmounts {
	color: #99a3a6;
	font-size: 14px;
	margin-left: auto;
	color: black;
}

.totalAmount {
	color: #99a3a6;
	font-size: 14px;
	margin-left: auto;
	font-weight: 600;
	color: black;
}

.discount {
	color: var(--primary-red-400);
	font-size: 14px;
	margin-left: auto;
}

.discount2 {
	color: var(--primary-green-500) !important;
}

.total {
	color: #222222;
	font-size: 14px;
	font-weight: 600;
}

.address {
	padding-left: 1rem;
	padding-right: 1rem;
	color: #6b6b6b;
	font-size: 14px;
	margin-bottom: auto;
	margin-top: auto;
}

.listingBox {
	margin-left: auto;
	margin-right: auto;
	display: flex;
	flex-direction: column;
	width: inherit;
	background-color: white;
	padding: 1.5em;
	border-radius: 8px;
	overflow-y: scroll;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	margin-bottom: 2rem;
}

.listingBox1 {
	margin-left: auto;
	margin-right: auto;
	display: flex;
	flex-direction: column;
	width: inherit;
	background-color: white;
	padding: 1em;
	border-radius: 8px;
	height: fit-content;
	width: 80%;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.listingBoxInfo {
	margin-left: auto;
	margin-right: auto;
	display: flex;
	flex-direction: column;
	width: 60%;
	background-color: white;
	padding: 1em;
	border-radius: 8px;
	height: fit-content;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.leftSection1 {
	width: 45%;
}

.leftSection {
	width: 35%;
}
.rightSection {
	width: 60%;
	padding-left: 1rem;
	padding-right: 1rem;
	display: flex;
	flex-direction: column;
}

.title {
	font-size: 16px;
	font-weight: 800;
}

.qty {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-top: auto;
	padding-top: 0.5rem;
}
.add {
	display: flex;
	align-items: center;
	justify-content: center;
	color: #99a3a6;
	border-radius: 100%;
	height: 30px;
	width: 30px;
	border: 0.5px solid #99a3a6;
	padding: 7px;
}

.add:hover {
	cursor: pointer;
}

.totalQty {
	color: #99a3a6;
	margin-left: 1rem;
	margin-right: 1rem;
}

.section {
	margin-bottom: 1.5rem;
}

.listingPrice {
	background-color: rgba(99, 183, 99, 0.15);
	font-size: 16px;
	color: var(--primary-purple);
	font-weight: 600;
	border-radius: 8px;
	padding: 5px;
	padding-left: 20px;
	padding-right: 20px;
	width: fit-content;
	display: flex;
	flex-direction: row;
	position: relative;
}

.estRetail {
	color: #99a3a6;
	text-decoration: line-through;
	padding: 5px;
}

.prices {
	margin-top: 0.5rem;

	display: flex;
	flex-direction: row;
}

.buttonDelivery {
	width: 45%;
	height: 3em;
	margin-left: auto;
	margin-right: auto;
	color: var(--primary-purple) !important;
	background-color: white !important;
}

.buttonPickup {
	height: 3em;
	margin-left: auto;
	margin-right: auto;
	width: 45%;
}

.button {
	width: 100%;
}

.listImg {
	object-fit: contain;
	height: inherit;
	width: inherit;
}

.carouselImg {
	min-height: 100%;
	max-height: 100%;
	width: 100%;
	object-fit: contain;
}

/* Done */

.retailLink {
	color: var(--primary-purple);
}

.space {
	margin: 0.5em;
}

.marginAuto {
	margin-top: auto;
}

.minHeight {
	max-width: 100%;
}

.onHover {
	cursor: pointer;
}

.onHover:hover {
	color: var(--primary-purple);
}

.infoIcon {
	position: absolute;
	color: #99a3a6;
	right: 1;
	margin-left: 1px;
	cursor: pointer;
}
.header {
	padding-top: 1em;
	padding-bottom: 1em;
}

.marginInline {
	-webkit-margin-start: 1em;
	-webkit-margin-end: 1em;
	margin-inline: 2em;
}

.carousel {
	position: absolute;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 50%;
	transform: translateY(-50%);
}

/* InfoSection */
.cardSize {
	min-height: 300px;
	padding: 1em;
}

.cardBorder {
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	border-radius: 10px;
	padding: 2em;
}

.noMargin {
	margin-top: 0 !important;
}

.expiration {
	font-size: 1em;
	color: gray;
	font-style: italic;
}

.offerDiv {
	position: relative;

	display: flex;
	flex-direction: row;
	align-items: center;
	border-radius: 5px;
	border: 1px solid #dadada;
	padding: 1rem 1rem;
}

.offerDiv:hover {
	border: 1px solid var(--primary-purple);

	cursor: pointer;
}

.highestOfferDiv {
	border: 0.5px solid gray;
	background-color: #a0b3ff64;
	border-radius: 7px;
	padding: 0.5em;
	margin: 0.5em;
}

.highestOffer {
	text-align: center;
	font-weight: 600;
	line-height: 3em;
	color: #ffc247;
}

.profilePic {
	width: 2.5em;
	height: 2.5em;
	border-radius: 100%;
	object-fit: cover;
}

.offersRow {
	width: 100%;
}

.offerName {
	margin-left: 0.7em;
	width: 50%;
	display: inline-block;
	font-weight: 600;
}

.offerAmount {
	width: 50%;
	text-align: right;

	display: inline-block;
}

.senderInfo {
	width: 50%;
	display: inline-block;
	vertical-align: top;
}

.amountPill {
	padding: 0.2em;
	background-color: var(--primary-purple) 66;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	text-align: center;
	margin-right: 0;
	margin-left: auto;
	/* min-width: 80px; */
	width: 90px;

	font-size: 1.2em;
	color: var(--primary-purple);
	font-weight: 600;
}

.offerPercentage {
	padding: 0.2em;
	background-color: var(--primary-purple) 3a;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	text-align: center;
	width: 90px;
	margin-right: 0;
	margin-left: auto;
	font-size: 14px;
	color: #6b6b6b;
}

.fiftyPercent {
	width: 50%;
	display: inline-block;
}

.orderBreakdown {
	display: flex;
	flex-direction: row;
	margin-bottom: 0.2rem;
}

.containSummary {
	width: 50%;
	margin: auto;
	margin-top: 1em;
}

.summaryLabel {
	color: #6b6b6b;
	font-size: 14px;
	/* width: 60%;
	  text-align: left;
	  margin-right: auto;
	  margin-left: 0; */
}

.summaryTotal {
	font-size: 14px;
	margin-left: auto;
	color: black;
	/* color: #99a3a6;
	  font-size: 14px;
	  margin-left: auto;
	  margin-right: 0;
	  color: black; */
}

.offerCountdown {
	width: 50%;
	display: inline-block;
}

.offerDeleteDiv {
	width: 50%;
	text-align: right;
	display: inline-block;
}

.deleteOffer {
	position: absolute;
	top: 0px;
	right: 0px;
	color: rgba(255, 0, 0, 0.466);
}

.deleteOffer:hover {
	cursor: pointer;
}

.expiresIn {
	color: gray;
	margin-top: 1em;
}

.topRow {
	width: 100%;
	height: 3em;
	text-align: center;
	position: relative;
	line-height: 3em;
}

.cross {
	height: 1.1em;
	width: 1.1em;
}

.counterOffer {
	box-shadow: 3px 3px 5px 2px rgba(0, 0, 0, 0.2);
	width: 100%;
	text-align: center;
	/* height: 5em; */
	border-radius: 7px;
	margin-top: 1em;
	padding: 1em;
}

.counterOffer:hover {
	box-shadow: 3px 3px 5px 5px rgba(0, 0, 0, 0.2);
}

.counterOfferAmount {
	border-radius: 10px;
	padding: 0.7em;
	background-color: var(--primary-purple) 66;
	font-size: 1.2em;
	width: fit-content;
	margin: auto;
	text-align: center;
	color: var(--primary-purple);
	font-weight: 600;
}

.centerCounterOffer {
	margin: auto;
	width: fit-content;
	margin-top: 0.5em;
}

.acceptOffer {
	background-color: var(--primary-purple);
	width: 50%;
	text-align: center;
	display: inline-block;
	color: white;
	border-radius: 5px 0px 0px 5px;
	padding: 0.3em;
	margin-top: 0.5em;
}

.sendNewOffer {
	background-color: #56a556;
	width: 50%;
	text-align: center;
	display: inline-block;
	color: white;
	border-radius: 0px 5px 5px 0px;
	padding: 0.3em;
	margin-top: 0.5em;
}

.counterOffer:hover {
	cursor: pointer;
}

.acceptOffer:hover {
	cursor: pointer;
}

@media (max-width: 1040px) {
	.cardSize {
		min-height: 300px;
	}
}

@media (max-width: 800px) {
	.cardSize {
		min-height: 200px;
	}

	.listingBox1 {
		width: 100%;
	}

	.cardBorder {
		box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
		border-radius: 10px;
		padding: 0.2em;
	}
}

@media (max-width: 420px) {
	.cardSize {
		min-height: 200px;
	}
	.marginTop {
		margin-top: 0em;
	}
}

/* image sizing */

.price {
	margin-bottom: 1px;
	font-size: 1.5rem;
	color: var(--text-main);
	font-weight: 600;
}

/* button div */
.buttonDiv {
	display: grid;
	width: 90%;
	margin: auto;
	position: relative;
	padding: 1rem;
}

.cartButtonSize {
	width: 100%;
}

.titleSpecs {
	font-weight: 600;
}

.listItemSpecs {
	margin-bottom: 1;
	margin-top: 1;
}

.subTotal {
	text-align: center;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

	padding: 10px;
	border-radius: 10px;
}

.conditionBadgeSize {
	display: grid;
	max-width: 200px;
	margin-top: 1px;
	color: white;
	background-color: #99a3a6;
	width: auto;
	height: auto;
	text-align: center;
	display: inline;
	padding: 4px;
	border-radius: 7px;
}

.distanceBadgeSize {
	display: grid;
	width: 20%;
	margin-left: 30px;
	margin-top: 1px;
}
