.separator {
  display: flex;
  align-items: center;
  max-width: 100%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.separatorText {
  color: #99a3a6;
  padding: 0 0.5rem;
  margin-bottom: 0rem !important;
}

.line {
  flex: 1;
  height: 1px;
  background-color: #eeeeee;
}
