.headerBox {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.main {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.seeAll {
  font-size: 18px;
  margin-bottom: 0px;
  font-weight: 400;
  font-family: "Editorial";

  color: var(--text-body);
}

.seeAll:hover {
  color: var(--text-main);
  cursor: pointer;
}

.header {
  margin-right: auto;
  font-size: 20px;
  font-family: "Editorial";

  margin-bottom: 0rem;

  font-weight: 500;
  color: var(--text-main);
}

.header1 {
  margin-right: auto;
  font-size: 32px;
  font-family: "Editorial";

  margin-bottom: 0rem;
  color: var(--text-main);
}

.Link {
  text-decoration: none;
}

.space {
  margin-top: 2rem;
}

.alignCenter {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-bottom: 2.5em;
}

@media (max-width: 990px) {
  .alignCenter {
    width: 100%;
  }
  .header1 {
    font-size: 18px;
    font-weight: 500;
  }
}

@media (max-width: 600px) {
  .header {
    font-size: 18px;
  }

  .seeAll {
    font-size: 16px;
  }

  .main {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}
