.main {
  max-width: var(--max-width);
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 800px) {
  .main {
    padding-left: 0rem;
    padding-right: 0rem;
  }
}
