.topRowSummary {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	background-color: var(--primary-bg);
	border-top-right-radius: 8px;
	border-top-left-radius: 8px;
	padding: 15px;

	width: 100%;
	gap: 0.5rem;
}

.discount {
	color: var(--primary-green-500) !important;
}

.orderMain {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 1rem;
}
.miniInfoSectionDetails {
	display: inline-block;
	margin: 1em;

	border: 1px solid gray;
	background-color: white;
	color: black;
	margin-left: 2em;
	padding: 0.5em;
	cursor: pointer;
	border-radius: 4px;
}

.cardDiv {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	width: 100%;
}

.cardPreview {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin: auto;
	width: 100%;
	border: 0.5px solid rgba(189, 189, 189, 0.627);
	border-radius: 7px;
	padding: 0.7em 0.4rem;
	color: gray;
	position: relative;
}

.statusUpdates {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.cardLogo {
	max-height: 2em;
	max-width: 2em;
	display: inline;
	margin-left: 1em;
}

.cardNumber {
	text-align: left;
	width: 50%;
	margin-left: 2em;
	/* margin-right: 3em; */
	color: #6b6b6b;
}

.paymentLogo {
	max-width: 4em;
	max-height: 1.5em;

	margin-left: 1em;
}

/* topNavigation */

.topNav {
	border: 1px solid var(--primary-border);
	display: flex;
	flex-direction: row;
	background-color: var(--primary-bg);
	align-items: center;
	padding: 5px 15px;
	border-radius: 8px;
	gap: 1rem;

	justify-content: space-between;
}

.miniInfoSection {
	display: flex;
	flex-direction: column;
}

.sectionDetailsHeader {
	color: var(--text-body);
	margin-bottom: 0px;
	font-weight: 300;
	font-size: 12px;
}

.sectionDetailsValue {
	color: var(--text-main);
	margin-bottom: 0px;
	font-size: 12px;
	font-weight: 500;
}

.sectionDetailsValueRed {
	color: var(--primary-red-500);
	margin-bottom: 0px;
	font-size: 12px;
	font-weight: 500;
}

.navButtons {
	display: flex;
	flex-direction: row;
}
.printButton {
	background-color: transparent;
	color: var(--text-main);
	border: 1px solid var(--text-main);
	font-size: 14px;
	margin-right: 0.5ch;
	border-radius: 3px;
	padding: 5px 15px;
	height: fit-content;
	text-decoration: none;
	cursor: pointer;
}

.printButton:hover {
	opacity: 0.4;
	color: var(--text-main);
}

.addDeliveryButton {
	background-color: transparent;
	border: 1px solid var(--text-main);

	color: var(--text-main);
	font-size: 14px;
	border-radius: 3px;
	padding: 5px 15px;
	height: fit-content;
}

.addDeliveryButton:hover {
	opacity: 0.4;
}

/* Order Details Section */

.mainOrderDiv {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
}

.col {
	display: flex;
	flex-direction: column;
	width: 65%;
	gap: 0.75rem;
}

.col1 {
	display: flex;
	flex-direction: column;
	width: 33%;
}

.orderDiv {
	border: 1px solid var(--primary-border);
	border-radius: 8px;
	height: fit-content;
	margin-bottom: 1rem;
	background-color: white;
}

.topRowDetailsHeader {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid var(--primary-border);
}

.mainText {
	font-size: 14px;
	font-weight: 500;
	color: var(--text-main);
	margin-bottom: 0rem;
	padding: 15px 15px;
}

.mainText1 {
	font-size: 16px;
	font-weight: 500;
	color: var(--text-main);
	margin-bottom: 1rem;
}

.productsSection {
	padding: 15px 15px;
	padding-bottom: calc(15px - 0.5rem);
	width: 100%;
}

.listingDiv {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-bottom: 0.5rem;
}

.listingImg {
	max-height: 70px;
	max-width: 70px;
	min-height: 70px;
	min-width: 70px;
	object-fit: cover;
	border-radius: 3px;
	border: 1px solid var(--primary-border);
}

.listingInfo {
	padding-left: 1rem;
	display: flex;
	height: 100%;
	gap: 5px;
	flex-direction: column;
}

.listingLink {
	text-decoration: none !important;
}

.listingTitle {
	font-size: 14px;
	color: var(--text-main);
	font-weight: 400;
	font-family: 'Editorial';
	line-height: 150%;
	margin-bottom: 0rem;
	text-decoration: none;
}

.listingTitle:hover {
	text-decoration: underline;
}

.additionalListingInfo {
	display: flex;
	flex-direction: column;
	gap: 5px;
}

.discountBox {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 5px;
	border: 1px solid var(--primary-border);
	border-radius: 1px;
	padding: 0.75rem 1rem;
}

.subtract {
	color: var(--primary-red-500) !important;
}

.prices {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 5px;
}
.listingPrice {
	font-size: 14px;
	color: var(--primary-red-400);
	font-weight: 500;
	margin-bottom: 0rem;
}

.oldPrice {
	font-size: 14px;
	color: var(--text-mute);
	font-weight: 400;
	margin-bottom: 0rem;
	text-decoration: line-through;
}

.quantity {
	font-size: 12px;
	color: var(--text-mute);
	margin-bottom: 0rem;
}

.space {
	margin-top: 5px;
}

/* order Breakdown */

.contentWrapper {
	display: flex;
	flex-direction: column;
	padding: 15px;
	gap: 15px;
}

.separator {
	background-color: var(--primary-border);
	height: 1px;
	width: 100%;
}

.contentGroup {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.orderBreakdown {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 5px;
}

.infoDiv {
	display: flex;
	flex-direction: column;
	row-gap: 5px;
}

.breakdownRow {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 0rem;
}

.breakdownRowTotal {
	display: flex;
	flex-direction: row;
	width: 100%;
	margin-top: 0em;
}

.subRow {
	padding-left: 1em;

	display: flex;
	flex-direction: row;
}

.rowDesc {
	width: 70%;
	color: var(--text-body);
	font-size: 14px;
	max-height: 1.5em;
}

.rowDescTotal {
	width: 70%;
	font-weight: 500;
	color: var(--text-main);
	font-size: 16px;
	max-height: 1.5em;
}

.rowDescSub {
	width: 70%;
	color: var(--text-mute);
	font-size: 14px;
	max-height: 1.5em;
}

.rowPrice {
	width: 30%;
	color: var(--text-main);
	text-align: end;
	font-weight: 500;
	font-size: 14px;
}

.rowPriceSub {
	width: 30%;
	color: var(--text-body);
	text-align: end;
	font-size: 14px;
}

/* order Status */

.orderStatus {
	padding: 15px 15px;
	border-top: 1px solid var(--primary-border);
}
.rel {
	position: relative;
}

.sold {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	background-color: var(--tetradic-blue);
	text-align: center;
	color: white;
	font-weight: 600;
	opacity: 0.8;
	width: 100%;
}
.upcoming {
	display: flex;
	flex-direction: row;
	font-size: 14px;
}

.upcoming * {
	color: var(--text-body);
	opacity: 0.8; /* Move the opacity property to a child selector */
}
.achieved {
	display: flex;
	flex-direction: column;

	font-size: 14px;
}

.subText {
	display: flex;
	color: var(--text-body);
	font-size: 12px;
	margin-bottom: 0rem;
	margin-left: 25px;
}

.actionOfferTitle {
	text-transform: uppercase;
	color: var(--text-mute);
	font-size: 16px;
	margin-bottom: 5px;
}

.offerAmount {
	font-size: 20px;
	color: var(--text-main);
	font-weight: 500;
	margin-bottom: 5px;
}

.expires {
	font-size: 14px;
	display: flex;
	flex-direction: row;
	align-items: center;
	color: var(--primary-red-400);
}

.expire {
	margin-right: 0.5ch;
}

.expirationTime {
	margin-left: 0.5ch;
	display: flex;
	align-items: center;
}

.actionBox {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	border: 1px solid var(--primary-border);
	background-color: var(--primary-bg);
	padding: 10px 10px;
	border-radius: 3px;
}

.space2 {
	margin-top: 0.2rem;
}

.spacing {
	margin-top: 0.5rem;
}

.row {
	display: flex;
	flex-direction: row;
	align-items: center;
	position: relative;
}

.verticalLine {
	width: '2px';
	background-color: '#e0e0e0';
	flex-grow: 1;
}

.verticalLineActive {
	background-color: '#4caf50';
}

.roundActive {
	height: 15px;
	width: 15px;

	border-radius: 100%;
	background-color: var(--primary-purple);
}

.round {
	height: 15px;
	width: 15px;
	border-radius: 100%;
	background-color: var(--primary-bg-dark);
}

.textActive {
	display: flex;
	width: 100%;
	align-items: center;
	margin-bottom: 0rem;
	color: var(--text-main);
	margin-left: 10px;
	font-weight: 500;
	font-size: 16px;
}

.textNonActive {
	margin-bottom: 0rem;
	color: var(--text-mute);
	margin-left: 10px;
	font-size: 14px;
	font-weight: 400;
}

.column {
	position: relative;
	margin-top: auto;
	margin-bottom: auto;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.subTextBadge {
	position: absolute;
	top: -0.3rem;
	right: 0;
	margin-bottom: 0rem;
	background-color: var(--primary-bg);
	border: 1px solid var(--primary-border);
	padding: 0.2rem 0.8rem;
	border-radius: 20px;
	color: var(--text-main);
	font-size: 12px;
}

.digitBox {
	background-color: var(--primary-bg);
	border: 1px solid var(--primary-border);
	padding: 1rem;
	display: flex;
	flex-direction: column;
	border-radius: 8px;
}

.digitInput {
	border: 1px solid var(--primary-border);
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
	height: 100%;
	padding-left: 0.5rem;
	height: 2.2rem;
}
.digitRow {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.digitinputButton {
	height: 2.2rem;
	padding: 5px 15px;

	font-size: 14px;

	background-color: var(--primary-purple);
	color: white;
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
}

.digitinputButton:hover {
	background-color: var(--primary-green-500);
}

.buyNowButton {
	padding: 5px 15px;

	font-size: 14px;

	background-color: var(--primary-purple);
	color: white;
	margin-left: auto;
	border-radius: 3px;
}

.buyNowButton:hover {
	opacity: 0.8;
}

.buttons {
	display: flex;
	flex-direction: row;
	width: fit-content;
	margin-left: auto;
	margin-top: auto;
	margin-bottom: auto;
	gap: 5px;
}

.newOfferButton {
	font-size: 14px;
	background-color: transparent;
	color: var(--text-main);
	border: 0.5px solid var(--text-main);
	border-radius: 3px;
	padding: 5px 15px;
}

.newOfferButton:hover {
	opacity: 0.4;
}
.deleteOffer {
	text-decoration: underline;
	color: var(--text-mute);
	font-size: 14px;
	text-align: center;
}

/* PaymentMethod */

.paymentMethodSection {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.breakDownSection {
	width: 100%;
	margin: auto;
	padding: 15px 15px;
	font-size: 0.8em;
}

/* More */

.editButtom {
	font-size: 12px;
	color: var(--text-mute);
	margin-bottom: 0rem;
	cursor: pointer;

	text-decoration: underline;
}

.editButtom:hover {
	opacity: 0.5;
}

.editButton {
	font-size: 12px;
	color: var(--text-mute);
	cursor: pointer;
	margin-bottom: 0rem;
	padding-right: 1rem;
	text-decoration: underline;
}

.editButton:hover {
	opacity: 0.5;
}

.infoHeader {
	font-weight: 500;
	font-size: 14px;
	margin-bottom: 00rem;
}

.infoValue {
	font-size: 12px;
	color: var(--text-mute);
	margin-bottom: 0rem;
}
.infoValue2 {
	font-size: 12px;
	color: var(--text-mute);
	margin-bottom: 0rem;
	text-decoration: underline;
}

.infoValueGreen {
	font-size: 13px;
	color: var(--primary-purple);
	font-weight: 500;
	margin-bottom: 0ch;
}

.greenLink {
	font-size: 13px;
	color: var(--primary-purple);
	margin-bottom: 0ch;
	text-decoration: underline;
}
.greenLink:hover {
	color: var(--primary-green-500);
}

.offerInfo {
	background-color: rgba(255, 0, 0, 0.296);
	color: rgba(255, 0, 0, 0.612);
	border: 1px solid rgba(255, 0, 0, 0.612);
	border-radius: 7px;
	padding: 0.8em;
	margin: 0.8em;
}

.additionalActionButton {
	font-size: 14px;
	cursor: pointer;
}

.additionalActionButton:hover {
	font-weight: 500;
}

/* Modal */
.getHelpModal {
	padding: 1rem;
}

.modalHeader {
	width: 100%;
	text-align: center;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
	margin-bottom: 0rem;
}

.modalHeaderTitle {
	font-size: 16px;
	font-weight: 500;

	margin-bottom: 0rem;
}

.closeIcon {
	color: var(--text-body);
	width: 25px;
	height: 25px;
}

.modalBody {
	padding: 1rem;
}

.modalCol {
	display: flex;
	flex-direction: column;
	gap: 0.3rem;
}
.modalColMain {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	margin-bottom: 1rem;
}
.modalRow {
	display: flex;
	flex-direction: row;
}

.modalNumStep {
	border: 1px solid var(--primary-border);
	border-radius: 9999px;
	padding: 0.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 2rem;
	min-width: 2rem;
	min-height: 2rem;
	margin-right: 1rem;
	height: 2rem;
	margin-top: auto;
	margin-bottom: auto;
	font-size: 25px;
}

.modalSubText1 {
	font-size: 14px;
	font-weight: 300;
	margin-bottom: 0rem;
	color: var(--text-main);
}

.divider {
	width: 100%;
	border-bottom: 1px solid var(--primary-border);
}

.modalButton {
	cursor: pointer;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.buttonText {
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 0rem;
	color: var(--text-main);
	text-decoration: none;
}

.buttonText:hover {
	color: var(--text-body);
}

.arrowIconRight {
	color: var(--text-body);

	height: 20px;
}

.contactText {
	margin-top: 1rem;
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 0rem;
	color: var(--text-main);
	text-decoration: none;
}

.modalActionPassive {
	margin-top: 0.5rem;
	display: flex;
	flex-direction: row;
}
.modalActionBox {
	margin-right: 1rem;
	padding: 1rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	border: 1px solid var(--primary-border);
	border-radius: 8px;
}

.subModalText {
	font-size: 14px;
	line-height: 150%;
}

.modalStepExplain {
	font-size: 14px;
	font-weight: 500;
}

.getHelpButton {
	text-align: center;
	font-size: 14px;
	background-color: var(--primary-bg-light);
	color: var(--primary-purple);
	border: 1px solid var(--primary-purple);
	border-radius: 3px;
	padding: 5px 15px;
	margin-top: 1rem;
	cursor: pointer;
}

.inlineBlock {
	display: block;
	margin-bottom: 0.5rem;
}

.infoBox {
	border: 1px solid var(--primary-border);
	padding: 1rem;
	border-radius: 8px;
	margin-bottom: 1rem;
}

.col1 {
	font-size: 12px;
}

.col2 {
	font-size: 12px;
	font-weight: 400;
	color: var(--text-mute);
}
.modalActionIcon {
	width: 3rem;
	height: 3rem;
	margin-bottom: 0.5rem;
}

/* Placeholder */

.placeholderRow {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.placeholderDiv {
	width: 60%;
	height: fit-content;
	border-radius: 8px;

	border: 1px solid var(--primary-border);
}

.placeholderDiv1 {
	width: 100%;
	margin-bottom: 1rem;
	height: fit-content;
	border-radius: 8px;

	border: 1px solid var(--primary-border);
}
.placeHolderDescription {
	display: flex;
	flex-direction: row;
	padding: 15px 15px;
}

.placeHolderImg {
	max-height: 100px;
	max-width: 100px;
	min-height: 100px;
	min-width: 100px;
	object-fit: cover;
	border-radius: 3px;
	border: 1px solid var(--primary-border);
	background-size: 100% 200%;
	background-image: repeating-linear-gradient(
		-45deg,
		hsl(210, 27%, 96%) 0%,
		hsla(180, 3%, 78%, 0.433) 50%,
		hsl(210, 28%, 93%) 100%
	);
	animation: anim 2s infinite;
}

.placeHolderCol {
	width: 40%;
	margin-top: auto;
	margin-bottom: auto;
	padding-left: 15px;
	display: flex;
	flex-direction: column;
}

.placeHolderCol1 {
	width: 40%;
	padding-left: 15px;
	display: flex;
	flex-direction: column;
}

.placeHolderBox1 {
	width: 10rem;
	display: flex;
	flex-direction: column;
	height: 1.5rem;
	background-size: 100% 200%;
	background-image: repeating-linear-gradient(
		-45deg,
		hsl(210, 27%, 96%) 0%,
		hsla(180, 3%, 78%, 0.433) 50%,
		hsl(210, 28%, 93%) 100%
	);
	animation: anim 2s infinite;
	border-radius: 8px;
}
.placeHolderBox2 {
	margin-top: 0.5ch;
	width: 5rem;
	display: flex;
	flex-direction: column;
	height: 1.5rem;
	background-size: 100% 200%;
	background-image: repeating-linear-gradient(
		-45deg,
		hsl(210, 27%, 96%) 0%,
		hsla(180, 3%, 78%, 0.433) 50%,
		hsl(210, 28%, 93%) 100%
	);
	animation: anim 2s infinite;
	border-radius: 8px;
}

.placeholderBox {
	width: 100%;
	display: flex;
	flex-direction: column;
	height: 10rem;
	background-size: 100% 200%;
	background-image: repeating-linear-gradient(
		-45deg,
		hsl(210, 27%, 96%) 0%,
		hsla(180, 3%, 78%, 0.433) 50%,
		hsl(210, 28%, 93%) 100%
	);
	animation: anim 2s infinite;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
}

.orderActionDiv {
	background-color: var(--primary-bg-light);
	border-top: 1px solid var(--primary-border);
	padding: 15px;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
}

@keyframes anim {
	0% {
		background-position: 0% 100%;
	}
	50% {
		background-position: 100% 0%;
	}
	100% {
		background-position: 0% 100%;
	}
}

@media (max-width: 600px) {
	.mainOrderDiv {
		flex-direction: column;
	}

	.topNav {
		position: relative;
		flex-direction: column;
		align-items: flex-start;
		padding: 1rem;
		background-color: white;
		gap: 0.5rem;
	}

	.buttons {
		width: 100%;
	}
	.buyNowButton {
		min-width: 50%;
		width: inherit;
	}

	.newOfferButton {
		width: 50%;
	}

	.printButton {
		position: absolute;
		top: 1rem;
		right: 1rem;
	}

	.col {
		width: 100%;
	}

	.col1 {
		width: 100%;
	}

	.subText {
		font-size: 12px;
	}

	.miniInfoSection {
		gap: 1px;
	}

	.textActive {
		font-size: 16px;
	}

	.buttons1 {
		width: 100%;
	}

	.subTextBadge {
		font-size: 10px;
		position: relative;
		width: fit-content;
		margin-left: 25px;
	}
}
