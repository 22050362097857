.previousOfferInactive {
	display: flex;
	flex-direction: column;
}

.yourOffer {
	cursor: pointer;

	padding-bottom: 1rem;
	padding-top: 1rem;
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: center;
	border-bottom: 1px solid var(--primary-border);
}
.yourOfferLast {
	cursor: pointer;

	padding-bottom: 1rem;
	padding-top: 1rem;
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: center;
}

.offerRound {
	width: 15px;
	height: 15px;
	border-radius: 100%;
	margin-right: 10px;
	border: 1px solid var(--primary-purple);
	background-color: var(--primary-purple);
}

.offerRoundLatest {
	width: 15px;
	height: 15px;
	border-radius: 100%;
	margin-right: 10px;
	border: 1px solid var(--primary-purple);
	background-color: var(--primary-purple);
}

.counterOffer {
	cursor: pointer;
	padding-bottom: 1rem;
	padding-top: 1rem;
	border-bottom: 1px solid var(--primary-border);

	display: flex;
	flex-direction: column;

	justify-content: center;
}

.counterOfferLast {
	border-top: 1px solid var(--primary-border);

	cursor: pointer;
	padding-bottom: 1rem;
	padding-top: 1rem;

	display: flex;
	flex-direction: column;
	justify-content: center;
}

.counterOfferRound {
	width: 15px;
	height: 15px;
	min-height: 15px;
	min-width: 15px;
	border-radius: 100%;
	margin-right: 10px;
	border: 1px solid var(--tetradic-blue);

	background-color: var(--tetradic-blue);
}

.counterOfferRounLatest {
	width: 15px;
	height: 15px;
	min-height: 15px;
	min-width: 15px;
	border-radius: 100%;
	margin-right: 10px;
	border: 1px solid var(--tetradic-blue);

	background-color: var(--tetradic-blue);
}

.offerStatusNoBorder {
	border-top: 1px solid var(--primary-border);
	padding: calc(15px - 1rem) 15px;
	border-bottom: 0px;
}

.offerStatusMain {
	border: 1px solid var(--primary-border);
	padding: calc(15px - 1rem) 15px;
	border-bottom: 0px;
	border-top-right-radius: 8px;
	border-top-left-radius: 8px;
}

.offerStatusMainLatest {
	border-top: 1px solid var(--primary-border);
	background-color: #fafafa;
	padding: calc(15px - 1rem) 15px;
	border-bottom-right-radius: 8px;
	border-bottom-left-radius: 8px;
}

.offerStatusMainLatest1 {
	border: 1px solid var(--primary-border);
	background-color: #fafafa;
	padding: calc(15px - 1rem) 15px;
	border-bottom-right-radius: 8px;
	border-bottom-left-radius: 8px;
}

.row1 {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.offerDate {
	display: inline;
	margin-left: 0.5ch;
	font-size: 12px;
	color: var(--text-mute);
}

.offerText {
	font-size: 14px;
	color: var(--text-main);
	margin-right: 0.5rem;
}

.offerTextCurrent {
	font-size: 14px;
	margin-right: 0.5rem;

	color: var(--text-main);
}

.line {
	border-left: 1px solid #efeff0;
	content: '';
	display: block;
	height: 100%;
	left: 35px;
	position: absolute;
	top: 18px;
	width: 1px;
	z-index: 0;
}

.expires {
	margin-left: auto;
	font-size: 12px;
	display: flex;
	flex-direction: row;
	align-items: center;
	color: var(--primary-purple);
}

.deleted {
	margin-left: auto;
	font-size: 12px;
	display: flex;
	flex-direction: row;
	align-items: center;
	color: var(--text-mute);
}

.expire {
	margin-right: 0.5ch;
}

.expirationTime {
	margin-left: auto;
	display: flex;
	align-items: center;
}

.arrowUp {
	width: 15px;
	height: 15px;

	transform: rotate(180deg);
	transition: 0.2s linear;
}

.arrowDown {
	width: 15px;
	height: 15px;
	transition: 0.2s linear;
}

.arrowBox {
	margin-left: auto;
}

.row {
	align-items: center;
	display: flex;
	flex-direction: row;
}

/* CurrentOffer */
.currentOffer {
	padding-bottom: 1rem;
	padding-top: 1rem;
	display: flex;
	flex-direction: column;

	border-bottom: 1px solid var(--primary-border);
	gap: 1rem;
}
.currentOfferNoBorder {
	padding-bottom: 1rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;

	padding-top: 1rem;
}

.buyNowButton {
	padding: 5px 15px;

	font-size: 14px;

	background-color: var(--primary-purple);
	color: white;
	border-radius: 3px;
}

.buyNowButton:hover {
	opacity: 0.8;
}

.actions {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.5rem;
}

.deleteOfferButton {
	font-size: 14px;
	background-color: transparent;
	color: var(--text-main);
	border: 0.5px solid var(--text-main);
	border-radius: 3px;
	padding: 5px 15px;
}

.deleteOfferButton:hover {
	opacity: 0.4;
}

/* Modal */

.modalHeader {
	width: 100%;
	text-align: center;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
	margin-bottom: 0rem;
	border-bottom: 1px solid var(--primary-border);
}

.modalHeaderTitle {
	font-size: 16px;
	font-weight: 500;

	margin-bottom: 0rem;
}

.closeIcon {
	color: var(--text-body);
	width: 25px;
	height: 25px;
}

.modalBody {
	padding: 1rem;
}

.modalFooter {
	padding: 1rem;
	display: flex;
	flex-direction: row;
	gap: 0.5rem;
	border-top: 1px solid var(--primary-border);
}

.modalSubText1 {
	margin-bottom: 0rem;
	font-size: 12px;
	color: var(--text-mute);
}
.modalStepExplain {
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 2px;
}

.counterOfferBox {
	display: flex;
	flex-direction: column;
	margin-right: auto;
	gap: 0.5rem;
	margin-top: 0.5rem;
	width: 100%;
}

.noActions {
	/* display: none; */
}
.expires1 {
	font-size: 12px;
	color: var(--primary-purple);
}
@media (max-width: 600px) {
	.offerText {
		font-size: 12px;
	}

	.offerDate {
		font-size: 12px;
	}

	.offerTextCurrent {
		font-size: 12px;
	}

	.deleted {
		font-size: 12px;
		text-align: end;
	}

	.expires {
		font-size: 12px;
		margin-bottom: auto;
		white-space: nowrap;
	}

	.expirationTime {
		text-align: end;
		font-size: 10px;
		white-space: nowrap;
		display: flex;
		align-items: center;
	}

	.arrowBox {
		margin-bottom: auto;
		white-space: nowrap;
	}

	.offerStatusMain {
		padding: calc(10px - 1rem) 10p;
	}
}
