.header {
  margin-top: 3.5rem;
  font-size: 32px;
  margin-bottom: 2.5rem;
}

.needAttentionRow {
  display: grid;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 2rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1rem;
}

.needAttentionDiv {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: var(--primary-bg);
  border-radius: 4px;
  position: relative;
  border: 1px solid var(--primary-border);
  height: 100%;
  transition: all 0.2s ease-in-out;
}

.needAttentionDiv:hover {
  cursor: pointer;
  background-color: var(--primary-bg-dark);
  transform: scale(1.02);
}

.attentionNumber {
  font-size: 28px;
  font-weight: 500;
  color: var(--text-main);
}

.attentionFooter {
  color: var(--text-body);
  font-size: 16px;
  margin-top: 0.2rem;
}

.subHeader {
  height: 1.5em;
  font-size: 16px;
  color: var(--text-mute);
  margin-bottom: 0.2rem;
}

.attentionMark {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-purple);
  color: white;
  height: 1.5rem;
  width: 1.5rem;
  line-height: 1.3;
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 100%;
}

.icon {
  width: 80%;
  height: 80%;
}

.attentionTitle {
  display: flex;
  font-size: 20px;
  font-weight: 500;
  color: var(--text-body);
  justify-content: center;
  align-items: center;
  margin-bottom: 0.2rem;
}

@media (max-width: 800px) {
  .header {
    text-align: center;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 2.5rem;
    font-size: 25px;
  }

  .needAttentionRow {
    grid-template-columns: 1fr;
  }
}
