.main {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.headerBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0rem;
  justify-content: space-between;
}

.header {
  font-size: 20px;
  font-family: "Editorial";
  font-weight: 500;
  line-height: 150%;
  margin-bottom: 0rem;
}

.seeAll {
  font-size: 18px;
  font-weight: 400;
  font-family: "Editorial";
  color: var(--text-body);
  margin-bottom: 0rem;
  text-decoration: none;
}

.seeAll:hover {
  color: var(--text-main);
  cursor: pointer;
}
.center {
  display: grid;
  margin-left: auto;
  margin-right: auto;
  column-gap: 10px;
  row-gap: 20px;
  max-width: var(--max-width);
  grid-template-columns: 2fr 1fr 1fr 1fr;
}

@media (max-width: 600px) {
  .center {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
  }
  .button {
    font-size: 14px;
  }

  .header {
    font-size: 18px;
  }

  .seeAll {
    font-size: 16px;
  }
}
