.choices {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var(--primary-bg);
}

.main {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.choice {
  cursor: pointer;
  display: flex;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  height: 54px;
  min-width: 60px;
  padding: 1.25rem;
}

.activeChoice {
  font-size: 16px;

  font-weight: 600;
  border-bottom: 2px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
  min-width: 60px;
  padding: 1.25rem;
}

@media (max-width: 800px) {
  .choices {
    justify-content: center;
  }
}
