.main {
  display: grid;
  grid-row: 1 / 2;
  gap: 2rem;
  padding-top: 40px;
  max-width: var(--max-width);
  margin-left: auto;
  margin-right: auto;
}

.mainBanner {
  display: grid;

  background-color: white;
}

.header {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0 2.5rem;
}

.productGrid {
  grid-row: 2 / 2;
  display: grid;
}

.productContainer {
  /* ful row */
  grid-row: 1;
}

.leftSection {
  grid-row: 1 / 2;
  padding-bottom: 40px;
  max-width: 37.5rem;
  display: grid;
}

.textContainer {
  grid-area: 1 / 1 / 2 / 2;
  display: grid;
  gap: 1rem; /* Adds space between the h1, p, and button */
}

.buyButton {
  align-items: center;
  border: 1px solid var(--primary-purple);
  border-radius: 3px;
  color: var(--primary-purple);
  display: flex;
  font-family: "Editorial";
  font-size: 16px;
  text-decoration: none;
  width: fit-content;
  font-weight: 400;
  justify-content: center;
  margin-bottom: 0;
  padding: 10px 20px;
  transition: all 0.2s ease;
  margin-top: 0.75rem;
  white-space: nowrap;
}

.buyButton:hover {
  opacity: 0.5;
}

.h1 {
  font-size: 3.2rem;
  margin-bottom: 0;
  font-weight: 400 !important;
}

.link {
  text-decoration: none;
  color: var(--text-main);
}

.link:hover {
  text-decoration: none;
  color: var(--text-main);
}

.nextButton {
  padding: 0;
  border: none;
  width: 32px;
  height: 32px;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}

.nextIcon {
  color: var(--primary-purple);
  width: 24px;
  height: 24px;
}

.h1Colored {
  font-family: "Editorial";
  color: var(--primary-purple);
}

.p {
  color: var(--text-body);
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  margin-bottom: 0rem;
}

.rightSection {
  grid-column: 2 / 3;
  grid-row: 1 / 3;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.imageBlock {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* Aspect ratio of 16:9 */
  position: relative;
  overflow: hidden;
}

.imageContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container without distorting */
  position: absolute;
}

.progressBarSection {
  display: flex;
  width: calc(50% - 20px);
  align-items: center;
  gap: 8px;
  height: 3.125rem;
}

.buttonContainer {
  height: 3.125rem;
  border: none;
  outline: none;
  padding: 0;
  flex-grow: 1;
  position: relative;
}

.pagerItem {
  height: 4px;
  background-color: var(--primary-bg-dark);
  position: relative;
  display: block;
}

.pagerItemInner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: block;
}

.pagerItemInnerActive {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: block;
  background-color: var(--primary-purple);
  width: 0;
  animation-duration: 8s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-delay: 0.1s;
  animation-name: animation-hero-pager;
  display: block;
}

.pagerItemInnerDone {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: block;
  background-color: var(--primary-purple);
  width: 100%;
  display: block;
}

@keyframes animation-hero-pager {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .main {
    display: flex;
    padding-top: 1.5rem;
    gap: 1rem;
    flex-direction: column;
  }

  .image {
    width: 100%;
    height: 100%;
  }
  .textContainer {
    gap: 0.5rem;
  }

  .mainBanner {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .progressBarSection {
    width: 100%;
    height: 2.125rem;
  }

  .leftSection {
    padding-bottom: 0rem;
  }

  .nextButton {
    display: none;
  }

  /* .p {
    height: 63px;
  } */

  .productGrid {
    width: 100%;

    display: flex;
    flex-direction: row;
  }

  .productContainer {
    display: inline-grid;
    width: 100%;
  }

  .header {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .h1 {
    font-size: 1.875rem;
    line-height: 1.2666666667;
    margin-bottom: 0rem;
  }

  /* .buyButton {
    display: none;
  } */

  .p {
    font-size: 14px;
    line-height: 1.5;
  }
}
