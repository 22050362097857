/* order Breakdown */

.breakDownBox {
	background-color: white;
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-top: 0.5rem;
	padding: 15px 15px;
	border-radius: 8px;
	gap: 0.5rem;
	border: 1px solid var(--primary-border);
}

.priceSection {
	display: flex;
	flex-direction: row;
}

.breakdownRow {
	width: 100%;
	display: flex;
	flex-direction: row;
}

.breakdownRowTotal {
	display: flex;
	flex-direction: row;
	width: 100%;
	margin-top: 0.3em;
	padding-top: 0.3em;
}

.subRow {
	padding-left: 1em;

	display: flex;
	flex-direction: row;
}

.rowDesc {
	width: 70%;
	color: var(--text-body);
	font-size: 12px;
	max-height: 1.5em;
	margin-bottom: 0rem;
}

.rowDescTotal {
	width: 70%;
	font-weight: 500;
	color: var(--text-main);
	font-size: 14px;
	max-height: 1.5em;
	margin-bottom: 0rem;
}

.rowDescSub {
	width: 70%;
	color: var(--text-mute);
	font-size: 12px;
	max-height: 1.5em;
}

.rowPrice {
	width: 30%;
	color: var(--text-main);
	text-align: end;
	font-weight: 500;
	font-size: 14px;
	margin-bottom: 0rem;
}
.rowPriceTotal {
	width: 30%;
	color: var(--text-main);
	text-align: end;
	font-weight: 500;
	font-size: 14px;
	margin-bottom: 0rem;
}

.rowPriceSub {
	width: 30%;
	color: var(--text-body);
	text-align: end;
	font-size: 14px;
}

.discount {
	color: var(--primary-green-500) !important;
}
