.header {
	padding: 1rem;
	border-bottom: 1px solid var(--primary-border);
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.titleText {
	display: flex;
	align-items: center;
	margin-bottom: 0rem;
	font-size: 20px;
	font-weight: 500;
}

.closeIcon {
	cursor: pointer;
}

.sectionStore {
	margin: 1rem;
}

.textareaDiv {
	width: 70%;
	margin: auto;
}

.buttonDiv {
	width: 70%;
	margin: auto;
	text-align: end;
}

.sourceRow {
	cursor: pointer;
	padding: 0.5em;
	margin: auto;
	margin-top: 0.2em;
	margin-bottom: 0.2em;
	border: 0.3px solid rgb(191, 191, 191);
	border-radius: 4px;
	width: 70%;
}

.sourceRowError {
	cursor: pointer;
	padding: 0.5em;
	margin: auto;
	margin-top: 0.2em;
	margin-bottom: 0.2em;
	border: 0.3px solid rgb(163, 0, 0);
	border-radius: 4px;
	width: 70%;
}

.error {
	color: rgb(163, 0, 0);
	text-align: center;
	font-size: 14px;
}

.sourceRow2 {
	cursor: pointer;
	padding: 0.5em;
	margin: auto;
	margin-top: 0.2em;
	margin-bottom: 0.2em;
	border: 0.3px solid rgb(191, 191, 191);
	border-radius: 4px;
	width: 100%;
}

.selectedSourceRow {
	cursor: pointer;
	padding: 0.5em;
	margin: auto;
	margin-top: 0.2em;
	margin-bottom: 0.2em;
	border: 0.3px solid rgb(191, 191, 191);
	background-color: rgb(227, 227, 227);
	border-radius: 4px;
	width: 70%;
}

.submitButton {
	padding: 0.7em;
	border-radius: 4px;
	margin-top: 0.7em;
	margin-bottom: 0.7em;
	width: 100%;
	background-color: var(--primary-purple);
	color: white;
}

.submitButtonDisabled {
	padding: 0.7em;
	border-radius: 4px;
	margin-top: 0.7em;
	margin-bottom: 0.7em;
	width: 100%;
	background-color: var(--primary-purple);
	opacity: 0.4;
	color: white;
}
