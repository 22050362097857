.main {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.listingsGrid {
  width: 100%;
  justify-content: space-evenly;

  display: flex;
  flex-wrap: wrap;
}
.listing {
  display: flex;
  width: 24%;
  min-width: 15em;
  margin-bottom: 1em;
  border-radius: 8px;
  background-color: white;
}
.header {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

@media (min-width: 1300px) {
  .listing {
    min-width: 0;
    width: 24%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 990px) {
  .listing {
    min-width: 0;
    width: 24%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 600px) {
  .listing {
    min-width: 0;
    width: 45vw;
  }
}
