.main {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
  row-gap: 4rem;
}

.center {
  display: grid;
  margin-left: auto;
  margin-right: auto;
  column-gap: 10px;
  row-gap: 20px;
  max-width: var(--max-width);
  grid-template-columns: 2fr 1fr 1fr 1fr;
}

.heading {
  text-align: center;
  margin-bottom: 0rem;
  font-size: 28px;
  width: 100%;
  font-weight: 500;
}

.heading1 {
  margin-bottom: 0rem;
  font-size: 28px;
  width: 100%;
  font-weight: 500;
}
.heading2 {
  text-align: end;
  margin-bottom: 0rem;
  font-size: 28px;
  width: 100%;
  font-weight: 500;
}

.subHeading {
  letter-spacing: 1px;
  font-size: 18px;
}

.mute {
  color: #222222;
  font-size: 16px;
  margin-bottom: 0rem;
  margin-top: 1rem;
  font-weight: 300;
  max-width: 50%;
  line-height: 28px;
}

.mute2 {
  margin-left: auto;

  text-align: end;
  color: #222222;
  font-size: 16px;
  margin-bottom: 0rem;
  margin-top: 1rem;
  font-weight: 300;
  max-width: 50%;
  line-height: 28px;
}

.header {
  max-width: var(--max-width);
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 2rem;
}

.picture {
  object-fit: cover;
  width: 100%;
  height: 230px;
}

.bigPic {
  object-fit: cover;
  width: 100%;
  height: inherit;
}

.pictureLarge {
  object-fit: cover;
  width: 100%;
  height: auto;
}

.link1 {
  text-decoration: none;
  cursor: pointer;
  text-decoration: none;
  height: fit-content;
  height: 100%;
  color: #222222;
}

.link1:hover {
  color: #222222;
}

.link {
  text-decoration: none;
  cursor: pointer;
  text-decoration: none;
  height: fit-content;
  height: 100%;
  color: #222222;
}

.link:hover {
  color: #222222;
}

.section {
}

.descriptionOnScreen {
  display: flex;
  flex-direction: column;
  line-height: 25px;
  position: absolute;
  font-size: 22px;
  color: white;
  font-weight: 600;
  bottom: 0;
  padding-left: 1rem;
  padding-bottom: 1rem;
  text-shadow: -1px -1px 3px #020202, 2px 2px 4px #1b1b1b;
}

.infoSection {
  margin-top: auto;
  display: flex;
  flex-direction: column;
}

.description {
  font-size: 16px;
  line-height: 25px;
  height: calc(100% - 230px);

  display: flex;
  flex-direction: column;
}

.title {
  font-size: inherit;
  margin-top: 1rem;
  margin-bottom: 1rem;
  line-height: 25px;
  font-weight: 600;
}

.tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.tag {
  font-size: 14px;
  font-weight: 600;
  margin-right: 1ch;
}

.date {
  margin-top: 3%;
  font-size: small;
  width: 100%;
}

.authorName {
  font-size: 14px;
  margin-bottom: 0rem;
  color: #736e7e;
}

.descriptionOnScreen .authorName {
  font-size: 14px;
  color: white;
}

.buttonDiv {
  margin-top: 50px;
  width: 100%;
  text-align: center;

  height: fit-content;
}

.button {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  color: #222222;
  border: 2px solid #222222;
  font-weight: 700;
  width: 320px;
  line-height: 1.2em;
  font-size: 18px;
  padding: 12px 20px;
  text-decoration: none;
  transition: 0.1s linear;
}

.button:hover {
  text-decoration: none;
  color: #222222;
  background-color: #22222218;
  transition: 0.1s linear;
}

@media (max-width: 600px) {
  .listItem {
    width: 100%;
  }

  .main {
    margin-top: 0rem;
  }
  .center {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
  }

  .header {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 2rem;
    padding-top: 3rem;
  }

  .title {
    font-size: 20px;
  }

  .descriptionOnScreen {
    text-shadow: none;
    padding: 0rem;
    position: relative;
    color: #222222;
    display: flex;
  }
  .descriptionOnScreen .authorName {
    color: #222222;
  }

  .link {
    border-top: 1px solid #222222;
    display: flex;
    flex-direction: row;
  }

  .title {
    font-size: 16px;
  }

  .mute {
    max-width: 100%;

    width: 100%;
    font-size: 14px;
    text-align: center;
  }
  .heading1 {
    text-align: center;
  }

  .heading2 {
    text-align: center;
  }
  .mute2 {
    text-align: start;
    max-width: 100%;
    width: 100%;
    font-size: 12px;
  }

  .description {
    padding-left: 1rem;
  }

  .tag {
    font-size: 14px;
    font-family: "Editorial";
    font-weight: 400;
  }

  .picture {
    width: 110px;
    height: 160px;
  }
}
